@use '@shared/util-styling/mixins';

.membership-toolkit-section {
  background-color: var(--mdrt-color-backgrounds-bg-surface-02);
  padding-block: 56px;
  position: relative;

  &__heading {
    padding-bottom: var(--spacing-24);
  }

  &__description {
    color: var(--mdrt-color-text-on-light-primary);
    font: var(--mdrt-body-2);
  }

  &__tabs-wrapper {
    border-top: var(--mdrt-border-thickness-01) solid var(--mdrt-color-borders-dark-01);
    margin-top: var(--spacing-32);
    padding-top: var(--spacing-32);
  }

  &__content {
    position: relative;
    z-index: 1;
  }

  &__graphics {
    color: var(--mdrt-color-backgrounds-bg-surface-03);
    display: none;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

  @include mixins.breakpoint('medium') {
    padding-block: 96px 64px;

    &__graphics {
      display: block;
    }
  }
}
