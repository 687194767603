@use '@shared/util-styling/mixins';

.membership-toolkit-tabs {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-32);

  &__list {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-16);
  }

  &__content {
    flex: 1;
  }

  &__tab-item {
    @include mixins.chip;
  }

  &__description {
    color: var(--mdrt-color-text-on-light-primary);
    font: var(--mdrt-body-3-long);
    margin-top: var(--spacing-12);
  }

  &__item-skeleton {
    border-radius: 24px;
    display: flex;
    width: 170px;
  }

  &__description-skeleton {
    display: block;
    margin-top: var(--spacing-12);
  }

  @include mixins.breakpoint('medium') {
    flex-direction: row;

    &__list {
      flex-direction: column;
      width: 228px;
    }
  }
}

.membership-toolkit-links-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-32);
  margin-top: var(--spacing-32);

  &__item {
    flex: 1;
    gap: var(--spacing-16);
  }

  &__title {
    color: var(--mdrt-color-text-on-light-tertiary);
    font: var(--mdrt-title-4);
  }

  &__item-skeleton {
    width: 50%;
  }

  @include mixins.breakpoint('medium') {
    flex-direction: row;
  }
}
