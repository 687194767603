// Do not edit directly
// Generated on Tue, 10 Dec 2024 13:24:38 GMT

$mdrt-buttons-primary-bg-fill: var(--mdrt-buttons-primary-bg-fill, #ffcf00);
$mdrt-buttons-primary-bg-hover: var(--mdrt-buttons-primary-bg-hover, #ffe059);
$mdrt-buttons-primary-bg-disabled: var(--mdrt-buttons-primary-bg-disabled, #1c1c1c0d);
$mdrt-buttons-primary-text-enabled: var(--mdrt-buttons-primary-text-enabled, #1c1c1c);
$mdrt-buttons-primary-text-hover: var(--mdrt-buttons-primary-text-hover, #1c1c1c);
$mdrt-buttons-primary-text-disabled: var(--mdrt-buttons-primary-text-disabled, #1c1c1c4d);
$mdrt-buttons-primary-border-fill: var(--mdrt-buttons-primary-border-fill, #ffcf00);
$mdrt-buttons-primary-border-hover: var(--mdrt-buttons-primary-border-hover, #ffe059);
$mdrt-buttons-secondary-bg-fill: var(--mdrt-buttons-secondary-bg-fill, #ffffff);
$mdrt-buttons-secondary-bg-hover: var(--mdrt-buttons-secondary-bg-hover, #1c1c1c1a);
$mdrt-buttons-secondary-bg-disabled: var(--mdrt-buttons-secondary-bg-disabled, #1c1c1c0d);
$mdrt-buttons-secondary-text-enabled: var(--mdrt-buttons-secondary-text-enabled, #1c1c1c);
$mdrt-buttons-secondary-text-hover: var(--mdrt-buttons-secondary-text-hover, #1c1c1c);
$mdrt-buttons-secondary-text-disabled: var(--mdrt-buttons-secondary-text-disabled, #1c1c1c4d);
$mdrt-buttons-secondary-border-fill: var(--mdrt-buttons-secondary-border-fill, #1c1c1cb3);
$mdrt-buttons-secondary-border-hover: var(--mdrt-buttons-secondary-border-hover, #1c1c1cb3);
$mdrt-buttons-left-padding-leading-icon: var(--mdrt-buttons-left-padding-leading-icon, 16px);
$mdrt-buttons-border-thickness: var(--mdrt-buttons-border-thickness, 1px);
$mdrt-buttons-icon-size: var(--mdrt-buttons-icon-size, 24px);
$mdrt-buttons-radius: var(--mdrt-buttons-radius, 24px);
$mdrt-buttons-vertical-padding: var(--mdrt-buttons-vertical-padding, 8px);
$mdrt-buttons-horizontal-padding: var(--mdrt-buttons-horizontal-padding, 24px);
$mdrt-buttons-gap-between: var(--mdrt-buttons-gap-between, 8px);
$mdrt-buttons-size-standard-left-padding-leading-icon-standard: var(
  --mdrt-buttons-size-standard-left-padding-leading-icon-standard,
  16px
);
$mdrt-buttons-size-standard-icon-size: var(--mdrt-buttons-size-standard-icon-size, 24px);
$mdrt-buttons-size-standard-radius: var(--mdrt-buttons-size-standard-radius, 24px);
$mdrt-buttons-size-standard-vertical-padding: var(
  --mdrt-buttons-size-standard-vertical-padding,
  8px
);
$mdrt-buttons-size-standard-horizontal-padding: var(
  --mdrt-buttons-size-standard-horizontal-padding,
  24px
);
$mdrt-buttons-size-standard-gap-between: var(--mdrt-buttons-size-standard-gap-between, 8px);
$mdrt-buttons-size-standard-border-thickness: var(
  --mdrt-buttons-size-standard-border-thickness,
  1px
);
$mdrt-buttons-size-small-border-thickness: var(--mdrt-buttons-size-small-border-thickness, 1px);
$mdrt-buttons-size-small-icon-size: var(--mdrt-buttons-size-small-icon-size, 20px);
$mdrt-buttons-size-small-radius: var(--mdrt-buttons-size-small-radius, 16px);
$mdrt-buttons-size-small-vertical-padding: var(--mdrt-buttons-size-small-vertical-padding, 6px);
$mdrt-buttons-size-small-horizontal-padding: var(
  --mdrt-buttons-size-small-horizontal-padding,
  16px
);
$mdrt-buttons-size-small-left-padding-leading-icon: var(
  --mdrt-buttons-size-small-left-padding-leading-icon,
  12px
);
$mdrt-buttons-size-small-gap-between: var(--mdrt-buttons-size-small-gap-between, 8px);
$mdrt-chips-bg-fill: var(--mdrt-chips-bg-fill, #1c1c1c0d);
$mdrt-chips-bg-hover: var(--mdrt-chips-bg-hover, #1c1c1c1a);
$mdrt-chips-bg-fill-selected: var(--mdrt-chips-bg-fill-selected, #1853a3);
$mdrt-chips-bg-hover-selected: var(--mdrt-chips-bg-hover-selected, #184d95);
$mdrt-chips-text-fill: var(--mdrt-chips-text-fill, #1c1c1cb3);
$mdrt-chips-text-hover: var(--mdrt-chips-text-hover, #1c1c1ccc);
$mdrt-chips-text-fill-selected: var(--mdrt-chips-text-fill-selected, #ffffff);
$mdrt-chips-vertical-padding: var(--mdrt-chips-vertical-padding, 8px);
$mdrt-chips-horizontal-padding: var(--mdrt-chips-horizontal-padding, 16px);
$mdrt-chips-icon-size: var(--mdrt-chips-icon-size, 24px);
$mdrt-chips-corner-radius: var(--mdrt-chips-corner-radius, 24px);
$mdrt-chips-gap-between-items: var(--mdrt-chips-gap-between-items, 8px);
$mdrt-chips-select-chips-bg-fill: var(--mdrt-chips-select-chips-bg-fill, #1c1c1c0d);
$mdrt-chips-select-chips-bg-hover: var(--mdrt-chips-select-chips-bg-hover, #1c1c1c1a);
$mdrt-chips-select-chips-bg-fill-selected: var(--mdrt-chips-select-chips-bg-fill-selected, #1853a3);
$mdrt-chips-select-chips-bg-hover-selected: var(
  --mdrt-chips-select-chips-bg-hover-selected,
  #184d95
);
$mdrt-chips-select-chips-text-fill: var(--mdrt-chips-select-chips-text-fill, #1c1c1cb3);
$mdrt-chips-select-chips-text-hover: var(--mdrt-chips-select-chips-text-hover, #1c1c1ccc);
$mdrt-chips-select-chips-text-fill-selected: var(
  --mdrt-chips-select-chips-text-fill-selected,
  #ffffff
);
$mdrt-chips-action-chips-bg-fill: var(--mdrt-chips-action-chips-bg-fill, #1c1c1c0d);
$mdrt-chips-action-chips-bg-hover: var(--mdrt-chips-action-chips-bg-hover, #1c1c1c1a);
$mdrt-chips-action-chips-bg-fill-on-dark: var(--mdrt-chips-action-chips-bg-fill-on-dark, #18365f);
$mdrt-chips-action-chips-bg-hover-on-dark: var(--mdrt-chips-action-chips-bg-hover-on-dark, #ffffff);
$mdrt-chips-action-chips-text-fill: var(--mdrt-chips-action-chips-text-fill, #1c1c1cb3);
$mdrt-chips-action-chips-text-hover: var(--mdrt-chips-action-chips-text-hover, #1c1c1ccc);
$mdrt-chips-action-chips-text-fill-on-dark: var(
  --mdrt-chips-action-chips-text-fill-on-dark,
  #ffffff
);
$mdrt-chips-action-chips-text-fill-high-emphasis: var(
  --mdrt-chips-action-chips-text-fill-high-emphasis,
  #1c1c1c
);
$mdrt-chips-action-chips-bg-fill-high-emphasis: var(
  --mdrt-chips-action-chips-bg-fill-high-emphasis,
  #ffcf00
);
$mdrt-chips-action-chips-bg-hover-high-emphasis: var(
  --mdrt-chips-action-chips-bg-hover-high-emphasis,
  #ffe059
);
$mdrt-chips-action-chips-text-hover-on-dark: var(
  --mdrt-chips-action-chips-text-hover-on-dark,
  #1c1c1c
);
$mdrt-chips-action-chips-text-hover-high-emphasis: var(
  --mdrt-chips-action-chips-text-hover-high-emphasis,
  #1c1c1c
);
$mdrt-chips-filter-chips-bg-fill: var(--mdrt-chips-filter-chips-bg-fill, #1c1c1c0d);
$mdrt-chips-filter-chips-bg-hover: var(--mdrt-chips-filter-chips-bg-hover, #1c1c1c1a);
$mdrt-chips-filter-chips-text-fill: var(--mdrt-chips-filter-chips-text-fill, #1c1c1cb3);
$mdrt-chips-filter-chips-text-hover: var(--mdrt-chips-filter-chips-text-hover, #1c1c1ccc);
$mdrt-chips-size-standard-vertical-padding: var(--mdrt-chips-size-standard-vertical-padding, 8px);
$mdrt-chips-size-standard-horizontal-padding: var(
  --mdrt-chips-size-standard-horizontal-padding,
  16px
);
$mdrt-chips-size-standard-icon-size: var(--mdrt-chips-size-standard-icon-size, 24px);
$mdrt-chips-size-standard-corner-radius: var(--mdrt-chips-size-standard-corner-radius, 24px);
$mdrt-chips-size-standard-gap-between-items: var(--mdrt-chips-size-standard-gap-between-items, 8px);
$mdrt-chips-size-small-vertical-padding: var(--mdrt-chips-size-small-vertical-padding, 6px);
$mdrt-chips-size-small-horizontal-padding: var(--mdrt-chips-size-small-horizontal-padding, 12px);
$mdrt-chips-size-small-icon-size: var(--mdrt-chips-size-small-icon-size, 20px);
$mdrt-chips-size-small-gap-between-items: var(--mdrt-chips-size-small-gap-between-items, 8px);
$mdrt-chips-size-small-corner-radius: var(--mdrt-chips-size-small-corner-radius, 16px);
$mdrt-badge-bg-fill-static: var(--mdrt-badge-bg-fill-static, #ffffff);
$mdrt-badge-bg-fill-action: var(--mdrt-badge-bg-fill-action, #1c1c1c0d);
$mdrt-badge-bg-fill-action-hover: var(--mdrt-badge-bg-fill-action-hover, #1c1c1c1a);
$mdrt-badge-text-fill-static: var(--mdrt-badge-text-fill-static, #1c1c1c);
$mdrt-badge-text-fill-action: var(--mdrt-badge-text-fill-action, #1c1c1ccc);
$mdrt-badge-text-fill-action-hover: var(--mdrt-badge-text-fill-action-hover, #1c1c1c);
$mdrt-badge-vertical-padding: var(--mdrt-badge-vertical-padding, 2px);
$mdrt-badge-horizontal-padding: var(--mdrt-badge-horizontal-padding, 4px);
$mdrt-badge-horizontal-padding-right-icon: var(--mdrt-badge-horizontal-padding-right-icon, 4px);
$mdrt-badge-corner-radius: var(--mdrt-badge-corner-radius, 4px);
$mdrt-badge-icon-size: var(--mdrt-badge-icon-size, 16px);
$mdrt-badge-static-bg-fill: var(--mdrt-badge-static-bg-fill, #ffffff);
$mdrt-badge-static-text-fill: var(--mdrt-badge-static-text-fill, #1c1c1c);
$mdrt-badge-static-bg-fill-on-primary: var(--mdrt-badge-static-bg-fill-on-primary, #18365f);
$mdrt-badge-static-text-fill-on-primary: var(--mdrt-badge-static-text-fill-on-primary, #ffffff);
$mdrt-badge-clickable-bg-fill: var(--mdrt-badge-clickable-bg-fill, #1c1c1c0d);
$mdrt-badge-clickable-bg-fill-hover: var(--mdrt-badge-clickable-bg-fill-hover, #1c1c1c1a);
$mdrt-badge-clickable-text-fill: var(--mdrt-badge-clickable-text-fill, #1c1c1ccc);
$mdrt-badge-clickable-text-fill-hover: var(--mdrt-badge-clickable-text-fill-hover, #1c1c1c);
$mdrt-badge-clickable-bg-fill-on-primary: var(--mdrt-badge-clickable-bg-fill-on-primary, #ffffff1a);
$mdrt-badge-clickable-bg-fill-hover-on-primay: var(
  --mdrt-badge-clickable-bg-fill-hover-on-primay,
  #ffffff33
);
$mdrt-badge-clickable-text-fill-on-primary: var(
  --mdrt-badge-clickable-text-fill-on-primary,
  #ffffffcc
);
$mdrt-badge-clickable-text-fill-hover-on-primary: var(
  --mdrt-badge-clickable-text-fill-hover-on-primary,
  #ffffff
);
$mdrt-icon-button-bg-fill: var(--mdrt-icon-button-bg-fill, #1c1c1c0d);
$mdrt-icon-button-bg-hover: var(--mdrt-icon-button-bg-hover, #1c1c1c1a);
$mdrt-icon-button-icon-fill: var(--mdrt-icon-button-icon-fill, #1c1c1cb3);
$mdrt-icon-button-icon-hover: var(--mdrt-icon-button-icon-hover, #1c1c1ccc);
$mdrt-icon-button-padding: var(--mdrt-icon-button-padding, 8px);
$mdrt-icon-button-icon-size: var(--mdrt-icon-button-icon-size, 24px);
$mdrt-icon-button-corner-radius: var(--mdrt-icon-button-corner-radius, 20px);
$mdrt-icon-button-primary-bg-fill: var(--mdrt-icon-button-primary-bg-fill, #1c1c1c0d);
$mdrt-icon-button-primary-bg-hover: var(--mdrt-icon-button-primary-bg-hover, #1c1c1c1a);
$mdrt-icon-button-primary-icon-fill: var(--mdrt-icon-button-primary-icon-fill, #1c1c1cb3);
$mdrt-icon-button-primary-icon-hover: var(--mdrt-icon-button-primary-icon-hover, #1c1c1ccc);
$mdrt-icon-button-primary-bg-fill-dark: var(--mdrt-icon-button-primary-bg-fill-dark, #ffffff1a);
$mdrt-icon-button-primary-bg-hover-dark: var(--mdrt-icon-button-primary-bg-hover-dark, #ffffff33);
$mdrt-icon-button-primary-icon-fill-dark: var(--mdrt-icon-button-primary-icon-fill-dark, #ffffffb3);
$mdrt-icon-button-primary-icon-hover-dark: var(
  --mdrt-icon-button-primary-icon-hover-dark,
  #ffffffcc
);
$mdrt-icon-button-secondary-bg-hover: var(--mdrt-icon-button-secondary-bg-hover, #1c1c1c1a);
$mdrt-icon-button-secondary-icon-fill: var(--mdrt-icon-button-secondary-icon-fill, #1c1c1cb3);
$mdrt-icon-button-secondary-icon-hover: var(--mdrt-icon-button-secondary-icon-hover, #1c1c1ccc);
$mdrt-icon-button-secondary-bg-hover-dark: var(
  --mdrt-icon-button-secondary-bg-hover-dark,
  #ffffff33
);
$mdrt-icon-button-secondary-icon-fill-dark: var(
  --mdrt-icon-button-secondary-icon-fill-dark,
  #ffffffb3
);
$mdrt-icon-button-secondary-icon-hover-dark: var(
  --mdrt-icon-button-secondary-icon-hover-dark,
  #ffffffcc
);
$mdrt-icon-button-size-standard-padding: var(--mdrt-icon-button-size-standard-padding, 8px);
$mdrt-icon-button-size-standard-icon-size: var(--mdrt-icon-button-size-standard-icon-size, 24px);
$mdrt-icon-button-size-standard-corner-radius-standard: var(
  --mdrt-icon-button-size-standard-corner-radius-standard,
  20px
);
$mdrt-icon-button-size-small-padding-small: var(--mdrt-icon-button-size-small-padding-small, 6px);
$mdrt-icon-button-size-small-icon-size-small: var(
  --mdrt-icon-button-size-small-icon-size-small,
  20px
);
$mdrt-icon-button-size-small-corner-radius-small: var(
  --mdrt-icon-button-size-small-corner-radius-small,
  16px
);
$mdrt-icon-button-selected-bg-fill: var(--mdrt-icon-button-selected-bg-fill, #1853a3);
$mdrt-icon-button-selected-bg-fill-dark: var(--mdrt-icon-button-selected-bg-fill-dark, #ffffff);
$mdrt-icon-button-selected-icon-fill: var(--mdrt-icon-button-selected-icon-fill, #ffffff);
$mdrt-icon-button-selected-icon-fill-dark: var(--mdrt-icon-button-selected-icon-fill-dark, #1853a3);
$mdrt-icon-button-selected-bg-fill-hover: var(--mdrt-icon-button-selected-bg-fill-hover, #184d95);
$mdrt-icon-button-selected-bg-fill-dark-hover: var(
  --mdrt-icon-button-selected-bg-fill-dark-hover,
  #e8eef6
);
$mdrt-tooltip-bg-fill: var(--mdrt-tooltip-bg-fill, #ffffff);
$mdrt-tooltip-bg-fill-inverse: var(--mdrt-tooltip-bg-fill-inverse, #1c1c1c);
$mdrt-tooltip-text-fill: var(--mdrt-tooltip-text-fill, #1c1c1c);
$mdrt-tooltip-text-fill-inverse: var(--mdrt-tooltip-text-fill-inverse, #ffffff);
$mdrt-tooltip-padding: var(--mdrt-tooltip-padding, 8px);
$mdrt-tooltip-corner-radius: var(--mdrt-tooltip-corner-radius, 4px);
$mdrt-alerts-bg-fill-error: var(--mdrt-alerts-bg-fill-error, #fbe9e8);
$mdrt-alerts-bg-fill-informational: var(--mdrt-alerts-bg-fill-informational, #eaf0f8);
$mdrt-alerts-bg-fill-success: var(--mdrt-alerts-bg-fill-success, #e5f3e7);
$mdrt-alerts-text-error: var(--mdrt-alerts-text-error, #aa2019);
$mdrt-alerts-text-informational: var(--mdrt-alerts-text-informational, #255391);
$mdrt-alerts-text-success: var(--mdrt-alerts-text-success, #066911);
$mdrt-alerts-icon-size: var(--mdrt-alerts-icon-size, 24px);
$mdrt-alerts-corner-radius: var(--mdrt-alerts-corner-radius, 8px);
$mdrt-alerts-padding: var(--mdrt-alerts-padding, 12px);
$mdrt-alerts-gap-between: var(--mdrt-alerts-gap-between, 8px);
$mdrt-alerts-bg-fill-non-semantic: var(--mdrt-alerts-bg-fill-non-semantic, #f4f4f4);
$mdrt-alerts-text-non-semantic: var(--mdrt-alerts-text-non-semantic, #1c1c1ccc);
$mdrt-button-link-text: var(--mdrt-button-link-text, #1c1c1c);
$mdrt-button-link-text-on-dark: var(--mdrt-button-link-text-on-dark, #ffffff);
$mdrt-button-link-text-hover: var(--mdrt-button-link-text-hover, #1c1c1c);
$mdrt-button-link-text-hover-on-dark: var(--mdrt-button-link-text-hover-on-dark, #1c1c1c);
$mdrt-button-link-bg-underline: var(--mdrt-button-link-bg-underline, #ffcf00);
$mdrt-button-link-bg-hover: var(--mdrt-button-link-bg-hover, #ffcf00);
$mdrt-button-link-icon-size: var(--mdrt-button-link-icon-size, 24px);
$mdrt-modal-bg-fill: var(--mdrt-modal-bg-fill, #ffffff);
$mdrt-modal-text-header: var(--mdrt-modal-text-header, #1c1c1c);
$mdrt-modal-text-content-area: var(--mdrt-modal-text-content-area, #1c1c1ccc);
$mdrt-modal-horizontal-padding-header: var(--mdrt-modal-horizontal-padding-header, 24px);
$mdrt-modal-horizontal-padding-footer: var(--mdrt-modal-horizontal-padding-footer, 24px);
$mdrt-modal-vertical-padding-header: var(--mdrt-modal-vertical-padding-header, 16px);
$mdrt-modal-vertical-padding-top-footer: var(--mdrt-modal-vertical-padding-top-footer, 8px);
$mdrt-modal-vertical-padding-down-footer: var(--mdrt-modal-vertical-padding-down-footer, 24px);
$mdrt-modal-corner-radius: var(--mdrt-modal-corner-radius, 16px);
$mdrt-modal-gap-between-header: var(--mdrt-modal-gap-between-header, 16px);
$mdrt-modal-icon-size: var(--mdrt-modal-icon-size, 24px);
$mdrt-input-bg-fill: var(--mdrt-input-bg-fill, #ffffff);
$mdrt-input-bg-read-only: var(--mdrt-input-bg-read-only, #fafafa);
$mdrt-input-bg-hover: var(--mdrt-input-bg-hover, #ffffff);
$mdrt-input-border-enabled: var(--mdrt-input-border-enabled, #1c1c1c4d);
$mdrt-input-border-hover: var(--mdrt-input-border-hover, #1c1c1c4d);
$mdrt-input-border-focus: var(--mdrt-input-border-focus, #1853a3);
$mdrt-input-border-error: var(--mdrt-input-border-error, #db2318);
$mdrt-input-border-disabled: var(--mdrt-input-border-disabled, #1c1c1c1a);
$mdrt-input-text-label: var(--mdrt-input-text-label, #1c1c1cb3);
$mdrt-input-text-value: var(--mdrt-input-text-value, #1c1c1c);
$mdrt-input-text-label-error: var(--mdrt-input-text-label-error, #db2318);
$mdrt-input-text-disabled: var(--mdrt-input-text-disabled, #1c1c1c4d);
$mdrt-input-bg-cursor: var(--mdrt-input-bg-cursor, #1853a3);
$mdrt-input-bg-icon-enabled: var(--mdrt-input-bg-icon-enabled, #1c1c1ccc);
$mdrt-input-icon-disabled: var(--mdrt-input-icon-disabled, #1c1c1c4d);
$mdrt-input-border-read-only: var(--mdrt-input-border-read-only, #1c1c1c00);
$mdrt-input-bg-disabled: var(--mdrt-input-bg-disabled, #ffffff);
$mdrt-input-text-placeholder: var(--mdrt-input-text-placeholder, #1c1c1cb3);
$mdrt-input-text-read-only: var(--mdrt-input-text-read-only, #1c1c1c);
$mdrt-input-padding-vertical: var(--mdrt-input-padding-vertical, 8px);
$mdrt-input-padding-horizontal: var(--mdrt-input-padding-horizontal, 12px);
$mdrt-input-border-radius: var(--mdrt-input-border-radius, 8px);
$mdrt-input-gap-icon: var(--mdrt-input-gap-icon, 8px);
$mdrt-input-gap-label: var(--mdrt-input-gap-label, 2px);
$mdrt-input-size-icon: var(--mdrt-input-size-icon, 24px);
$mdrt-input-border-thickness-hover: var(--mdrt-input-border-thickness-hover, 2px);
$mdrt-input-border-thickness-focus: var(--mdrt-input-border-thickness-focus, 2px);
$mdrt-card-bg-standard: var(--mdrt-card-bg-standard, #ffffff);
$mdrt-card-bg-featured: var(--mdrt-card-bg-featured, #1853a3);
$mdrt-card-text-headline: var(--mdrt-card-text-headline, #1c1c1c);
$mdrt-card-text-body: var(--mdrt-card-text-body, #1c1c1ccc);
$mdrt-card-text-metadata: var(--mdrt-card-text-metadata, #1c1c1cb3);
$mdrt-card-text-price: var(--mdrt-card-text-price, #1c1c1c);
$mdrt-card-text-member-count: var(--mdrt-card-text-member-count, #1c1c1c);
$mdrt-card-radius-large: var(--mdrt-card-radius-large, 16px);
$mdrt-card-radius-small: var(--mdrt-card-radius-small, 8px);
$mdrt-mobile-page-navigation-bg-selector: var(--mdrt-mobile-page-navigation-bg-selector, #e8eef6);
$mdrt-mobile-page-navigation-bg-text: var(--mdrt-mobile-page-navigation-bg-text, #1c1c1c);
$mdrt-mobile-page-navigation-bg-page-selected: var(
  --mdrt-mobile-page-navigation-bg-page-selected,
  #1c1c1c0d
);
$mdrt-mobile-page-navigation-bg-page: var(--mdrt-mobile-page-navigation-bg-page, #ffffff);
$mdrt-mobile-page-navigation-bg-page-hover: var(
  --mdrt-mobile-page-navigation-bg-page-hover,
  #1c1c1c1a
);
$mdrt-mobile-page-navigation-bg-selected-icon: var(
  --mdrt-mobile-page-navigation-bg-selected-icon,
  #1c1c1cb3
);
$mdrt-mobile-page-navigation-bg-chevron: var(--mdrt-mobile-page-navigation-bg-chevron, #1c1c1c);
$mdrt-filter-button-bg-fill: var(--mdrt-filter-button-bg-fill, #ffffff);
$mdrt-filter-button-border-fill: var(--mdrt-filter-button-border-fill, #1c1c1c33);
$mdrt-filter-button-border-hover: var(--mdrt-filter-button-border-hover, #1c1c1c4d);
$mdrt-filter-button-text-fill: var(--mdrt-filter-button-text-fill, #1c1c1ccc);
$mdrt-filter-button-icon-fill: var(--mdrt-filter-button-icon-fill, #1c1c1ccc);
