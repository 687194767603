@use '@shared/util-styling/mixins';

.score-section {
  $_padding-size-bottom: 72px;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  padding-bottom: $_padding-size-bottom;
  padding-top: var(--spacing-24);

  @include mixins.breakpoint('medium') {
    padding-top: var(--spacing-32);
  }

  &__button-wrapper {
    width: fit-content;
  }

  &__scores-container {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-40);

    @include mixins.breakpoint('large') {
      justify-content: space-between;
    }
  }
}

.score-section-heading {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  justify-content: flex-start;

  &__headline {
    font: var(--mdrt-headline-2);
    margin: 0;
    min-width: 65%;
  }

  @include mixins.breakpoint('medium') {
    flex-direction: row;
    justify-content: space-between;
  }
}
