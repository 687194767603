@use '../tokens';

@mixin chip {
  $_transition-style: background-color 0.1s ease;
  $_this: &;

  align-items: center;
  background: tokens.$mdrt-chips-action-chips-bg-fill-on-dark;
  border: none;
  border-radius: tokens.$mdrt-chips-corner-radius;
  color: tokens.$mdrt-chips-action-chips-text-fill-on-dark;
  cursor: pointer;
  display: flex;
  font: var(--chip-font);
  gap: tokens.$mdrt-chips-gap-between-items;
  padding: tokens.$mdrt-chips-vertical-padding tokens.$mdrt-chips-horizontal-padding;
  text-decoration: none;
  transition: $_transition-style;
  width: fit-content;

  &:focus {
    border-radius: tokens.$mdrt-chips-corner-radius;
  }

  &:hover {
    background: tokens.$mdrt-chips-action-chips-bg-hover-on-dark;
    color: tokens.$mdrt-chips-action-chips-text-hover-on-dark;
    text-decoration: none;
  }

  &:checked {
    background: tokens.$mdrt-chips-bg-fill-selected;
    color: tokens.$mdrt-chips-text-fill-selected;

    &:hover {
      background: tokens.$mdrt-chips-bg-hover-selected;
    }
  }

  &--theme-active {
    background: tokens.$mdrt-chips-bg-fill-selected;
    color: tokens.$mdrt-chips-text-fill-selected;

    &:hover {
      background: tokens.$mdrt-chips-bg-hover-selected;
      color: tokens.$mdrt-chips-text-fill-selected;
    }
  }

  &--theme-light {
    background: tokens.$mdrt-chips-bg-fill;
    color: tokens.$mdrt-chips-text-fill;

    &:hover {
      background: tokens.$mdrt-chips-bg-hover;
      color: tokens.$mdrt-chips-text-hover;
    }
  }

  &--theme-emphasis {
    background: tokens.$mdrt-chips-action-chips-bg-fill-high-emphasis;
    color: tokens.$mdrt-chips-action-chips-text-fill-high-emphasis;

    &:hover {
      background: tokens.$mdrt-chips-action-chips-bg-hover-high-emphasis;
    }
  }

  &--not-clickable {
    pointer-events: none;
  }

  &__icon-container {
    height: tokens.$mdrt-chips-size-standard-icon-size;
    width: tokens.$mdrt-chips-size-standard-icon-size;
  }

  &--small {
    border-radius: tokens.$mdrt-chips-size-small-corner-radius;
    font-size: var(--mdrt-font-size-1);
    padding: tokens.$mdrt-chips-size-small-vertical-padding
      tokens.$mdrt-chips-size-small-horizontal-padding;

    #{$_this}__icon-container {
      height: tokens.$mdrt-chips-size-small-icon-size;
      width: tokens.$mdrt-chips-size-small-icon-size;
    }
  }

  &--is-rounded {
    padding-inline: var(--spacing-8);
  }
}
